@tailwind base;

@layer base {
  h1 {
    @apply text-4xl   sm:text-5xl   md:text-6xl   lg:text-6xl   xl:text-7xl     2xl:text-8xl    4xl:text-[10rem];
  }
  h2 {
    @apply text-2xl   sm:text-3xl   md:text-4xl   lg:text-5xl   xl:text-6xl     2xl:text-7xl    4xl:text-9xl;
  }
  h3 {
    @apply text-xl    sm:text-2xl   md:text-3xl   lg:text-4xl   xl:text-5xl     2xl:text-6xl    4xl:text-7xl;
  }
  h4 {
    @apply text-lg    sm:text-xl    md:text-2xl   lg:text-3xl   xl:text-4xl     2xl:text-5xl    4xl:text-6xl;
  }
  h5 {
    @apply text-base  sm:text-lg    md:text-xl    lg:text-2xl   xl:text-3xl     2xl:text-4xl    4xl:text-5xl;
  }
  h6 {
    @apply text-sm    sm:text-base  md:text-lg    lg:text-xl    xl:text-2xl     2xl:text-3xl    4xl:text-4xl;
  }
  sub {
    @apply text-sm    sm:text-base  md:text-base  lg:text-lg    xl:text-xl       2xl:text-2xl    4xl:text-4xl;
  }
  p {
    @apply text-sm    sm:text-sm   md:text-base   lg:text-lg    xl:text-xl       2xl:text-2xl     4xl:text-4xl;
  }
  a {
    @apply text-sm    sm:text-sm   md:text-base   lg:text-base  xl:text-base     2xl:text-lg      4xl:text-2xl;
  }
  label {
    @apply text-xs    sm:text-sm   md:text-sm    lg:text-base   xl:text-lg       2xl:text-xl      4xl:text-2xl;
  }
  section {
    @apply rounded-2xl m-3         md:m-5        lg:m-7         xl:m-9           2xl:m-11         4xl:text-9xl;
  }
}
@tailwind components;

@layer components {
  .section-header {
    @apply pb-8       md:pb-10      lg:pb-12        xl:pb-14        2xl:pb-16       4xl:pb-20;
  }
  .section-wrapper-padding {
    @apply mx-auto px-4 md:px-8     lg:px-12        xl:px-16        2xl:px-20       4xl:px-28;
  }
  .feature-bullets {
    @apply mx-auto pl-2 text-xs md:text-sm lg:text-base xl:text-lg  2xl:text-xl     4xl:text-3xl;
  }
}
@tailwind utilities;

body {
  margin: 0;
  background: black;
  color: white;
  font-family: Poppins, Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.zoomeffect {
  animation: scale 10s;
}

@keyframes scale {
  0%  {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}