#hero {
  background-image: radial-gradient(circle at 85% 1%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 96%,transparent 96%, transparent 100%),radial-gradient(circle at 14% 15%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 1%,transparent 1%, transparent 100%),radial-gradient(circle at 60% 90%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 20%,transparent 20%, transparent 100%),radial-gradient(circle at 79% 7%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 78%,transparent 78%, transparent 100%),radial-gradient(circle at 55% 65%, hsla(190,0%,93%,0.05) 0%, hsla(190,0%,93%,0.05) 52%,transparent 52%, transparent 100%),linear-gradient(135deg, rgb(121,66,151),rgb(90,42,131));
}

#sales {
  background-color: #2a0d50
}

#redefine {
  background-color: #2a0d50
}

#overwhelmed {
  background-color: #2a0d50
}

#how-we {
  background-color: #2a0d50
}

#solution {
  background-color: #2a0d50
}

#contact-us {
  background-color: #2a0d50
}

.reveal {
  position: relative;
  transform: translateY(50px);
  opacity: 0;
  transition: all 2s ease;
}

.reveal.active  {
  transform: translateY(0);
  opacity: 1;
}

.gradient-overlay {

}

#indicator a {
  pointer-events: none;
}

.arrow {
  text-align: center;
  margin: 8% 0;
  color: #cecece;
}
.bounce {
  -moz-animation: bounce 6s infinite;
  -webkit-animation: bounce 6s infinite;
  animation: bounce 6s infinite;
}

@keyframes bounce {
  0%, 35%, 45%, 55%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(-15px);
  }
}

.ctabtn {
  background-image: -webkit-linear-gradient(45deg, #a855f7 50%, transparent 50%);
  background-image: linear-gradient(45deg, #a855f7 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 700ms ease-in-out;
}
.ctabtn-outline {
  background-image: -webkit-linear-gradient(45deg, #a855f7 50%, transparent 50%);
  background-image: linear-gradient(45deg, #a855f7 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 700ms ease-in-out;
}

.ctabtn:hover {
  background-position: 0;
}

.ctabtn-outline:hover {
  background-position: 0;
}

.cardgrid {
  /*grid-template-rows: auto auto auto 1fr auto*/
}

#navbar {
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  transition: top 0.3s;
}